import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEnterGameRequest } from '../../api/enter-game'
import { Registree, useRefreshAccount, useUserStore } from '../../components/page/user.store'
import { formatMoney } from '../../global'
import { Path } from '../../paths'
import { useEnteringStore, useMatchdayStore } from './game.store'
import { LabelledAction } from '../../components/dialogue/dialogue'

type Prompt = { show: boolean, title: string, message: string, additionalMessages: string[], actions: LabelledAction[], cancel?: () => void }
const noPrompt: Prompt = { show: false, title: 'No prompt', message: '', additionalMessages: [], actions: [] }

export function useEnterGameViewModel() {
    const refreshAccount = useRefreshAccount()
    const enterGame = useEnterGameRequest()
    const matchday = useMatchdayStore()
    const hasEntered = useEnteringStore()
    const user = useUserStore()
    const goTo = useNavigate()

    const promtMessage = useMemo(() => (user.signedIn ?
        (user as Registree).balance.tokens > 0
            ? `1 token will be deducted from your account. You will have ${(user as Registree).balance.tokens - 1} remaining tokens.`
            : `${formatMoney(matchday.stake)} will be deducted from your account. Your remaining balance will be ${formatMoney((user as Registree).balance.cash - matchday.stake)}.`
        : ''), [matchday.stake, user])

    const enterPrompt: Prompt = useMemo((): Prompt => ({
        show: true,
        title: 'Confirm Entry!',
        message: promtMessage,
        additionalMessages: user.signedIn ? (user as Registree).promotions.filter(x => x.howToClaim === "OnEntry").map(x => x.awardMessage) : [],
        actions: [{
            label: 'OK', action: async () => {
                await enterGame(matchday.id, 5, matchday.fixtures.map(f => ({ reference: f.reference, home: f.homeTeam.prediction, away: f.awayTeam.prediction })))
                setEntryPrompt(noPrompt)
                hasEntered()
                await refreshAccount()
            }
        }],
        cancel: () => {
            setEntryPrompt(noPrompt)
        }
    }), [promtMessage, user, enterGame, matchday.id, matchday.fixtures, hasEntered, refreshAccount])

    const denyEntryPrompt: Prompt = useMemo((): Prompt => ({
        show: true,
        title: 'Please make a deposit!',
        message: `There are not enough funds in your account to cover the £${matchday.stake} stake. Please make a deposit`,
        additionalMessages: [],
        actions: [{
            label: 'OK', action: async () => {
                setEntryPrompt(noPrompt)
                goTo(Path.account)
            }
        }]
    }), [goTo, matchday.stake])

    const [entryPrompt, setEntryPrompt] = useState<Prompt>(noPrompt)
    const [requiresSignIn, setRequiresSignIn] = useState<boolean>(false)    

    async function attemptEnter(stake : 'paid') {
        if (user.signedIn) {
            if(stake === 'paid' && ((user as Registree).balance.cash >= matchday.stake || (user as Registree).balance.tokens > 0))
                setEntryPrompt(enterPrompt)
            else
                setEntryPrompt(denyEntryPrompt)
        }
        else {
            setEntryPrompt(noPrompt)
            setRequiresSignIn(true)
        }
    }
    return {
        attemptEnter,
        entryPrompt,
        requiresSignIn: {
            show: requiresSignIn,
            onClose() { setRequiresSignIn(false) }
        }
    }
}
import { ReactNode } from 'react'
import styles from './panel.module.css'

function Panel({ title, strapline, action }: { title: string, strapline: ReactNode, action: () => void }) {
  return <div className={styles.panel} onClick={action}>
    <div className={styles.title}>{title}</div>
    <div className={styles.strapline}>{strapline}</div>
  </div>
}

type BetterPanelProps = { title: string, points: [ReactNode, ReactNode, ReactNode], cta: string }
export function BetterPanel({ title, points, cta }: BetterPanelProps) {
  return <div className={styles.betterPanel}>
    <div className={styles.title}>{title}</div>
    <ul className={styles.betterPanelList}>
      {points.map((p,i)=><li key={i} className={styles[`betterPanelListItem${i+1}`]}>{p}</li>)}
    </ul>
    <div className={styles.betterPanelCta}>{cta}</div>
  </div>
}

  const Straplines = {
    SignUpAndSecondGameIsFree() { return <><Highlight>SIGN UP</Highlight>, PLAY AND YOUR 2ND GAME IS FREE</> },
    PlayNowAndSecondGameIsFree() { return <><Highlight>PLAY NOW</Highlight> AND YOUR 2ND GAME IS FREE</> },
    AskTheExpert() { return <><Highlight>KINGY'S BLOG</Highlight> - PREDICTIONS FOR OUR GAMES</> },
    SignUpAndReferAFriend() { return <>USE YOUR <Highlight>REFERRAL LINK</Highlight> TO EARN FREE GAMES</> },
    ReferAFriend: "USE YOUR REFERRAL LINK TO EARN FREE GAMES",
    SignUpAndBuyFiveForFour() { return <><Highlight>BUY 5</Highlight> GAME ENTRIES FOR THE PRICE OF 4</> },
    BuyFiveForFour: "BUY 5 GAME ENTRIES FOR THE PRICE OF 4"
  }

  export function Highlight({ children }: { children: string }) {
    return <span className={styles.highlight}>{children}</span>
  }

  export const Panels = {    
    SecondGameFree(action: () => void, isSignedUp: boolean) {
      return isSignedUp
        ? <Panel title="2nd Game Free" strapline={<Straplines.PlayNowAndSecondGameIsFree />} action={action} />
        : <Panel title="2nd Game Free" strapline={<Straplines.SignUpAndSecondGameIsFree />} action={action} />
    },
    ReferAFriend(action: () => void, isSignedUp: boolean) {
      return isSignedUp
        ? <Panel title="Refer A Friend" strapline={Straplines.ReferAFriend} action={action} />
        : <Panel title="Refer A Friend" strapline={<Straplines.SignUpAndReferAFriend />} action={action} />
    },
    FiveForFour(action: () => void, isSignedUp: boolean) {
      return isSignedUp
        ? <Panel title="5 Games For £20" strapline={Straplines.BuyFiveForFour} action={action} />
        : <Panel title="5 Games For £20" strapline={<Straplines.SignUpAndBuyFiveForFour />} action={action} />
    },
    AskTheExpert(action: () => void) { return <Panel title="Ask The Expert" strapline={<Straplines.AskTheExpert />} action={action} /> }
  }
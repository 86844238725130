import { UseFormReturn } from 'react-hook-form'
import { Input } from './input'
import { DateOfBirth } from './date-of-birth'
import { SignupForm } from './signup.vm'

export function Personal({ form }: { form: UseFormReturn<SignupForm, object> }) {
  return <>
    <Name form={form} />
    <DateOfBirth form={form} />
    <Contact form={form} />
  </>
}

function Name({ form }: { form: UseFormReturn<SignupForm, object> }) {
  const { register, formState: { errors } } = form
  return <>
    <Input label="Firstname" errors={errors} {...register("firstname", { required: "Please enter your firstname" })} />
    <Input label="Surname" errors={errors} {...register("surname", { required: "Please enter your surname" })} />
  </>
}

function Contact({ form }: { form: UseFormReturn<SignupForm, object> }) {
  const { register, formState: { errors } } = form
  return <>
    <Input label="Email" type="email" errors={errors} {...register("email", {
      required: { value: true, message: "Please enter a valid email" },
      pattern: { value: /[^@\s\t\r\n]+@[^@\s\t\r\n]+\.[^@\s\t\r\n]{2,}/, message: "Please enter a valid email" }
    })} />
    <Input label="Mobile" errors={errors} {...register("mobile", {
      pattern: { value: /^(07\s*|\+?\s*\(?44\)?\s*7\s*)(\d\s*){9}$/, message: "Please enter a valid UK mobile" }
    })} />
  </>
}
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { Transaction } from '../features/account/account.types'
import { IsoDateTime, Money } from '../global'
import { useGet } from '../utilities/http/use-authenticated-api'
import routes from './routes'

type TransactionDto = { time: IsoDateTime, description: string, amount: Money, balance: Money }

export function useGetTransactionsRequest() {
  const get = useGet()
  return useCallback(async (): Promise<Transaction[]> => {
    return (await get<TransactionDto[]>(routes.transactions)).map(transaction => ({
      time: DateTime.fromISO(transaction.time),
      description: transaction.description,
      amount: transaction.amount,
      balance: transaction.balance
    }))
  }, [get])
}
import { DateTime } from 'luxon'
import { GamesListBuilder } from '../features/dashboard/specs/games-list/games-list-builder'
import { useCallback } from 'react'
/*import { useCallback } from "react"
import { Game } from "../features/dashboard/games-list/games-list.types"
import { useGet } from '../utilities/http/use-authenticated-api'
import routes from './routes'*/


type GameResponse = {
    game: { name: string },
    round: { id: string, name: string, start: string, end: string },
    offering: { stake: { cash: number }, pot: number }
}

export function useGetGames() {
    //const get = useGet()
    //return useCallback(async (): Promise<Game[]> => (await get<GameResponse[]>(routes.games)).map(toGame), [get])
    return useCallback(async () => Promise.resolve(new GamesListBuilder().list().map(toGame)),[])
}

function toGame(gameResponse: GameResponse) {
    return {
        game: gameResponse.game,
        round: {
            ...gameResponse.round,
            start: DateTime.fromISO(gameResponse.round.start),
            end: DateTime.fromISO(gameResponse.round.end)
        },
        offering: gameResponse.offering
    }
}
import styles from '../account.module.css'
import { DepositAmountForm, useDepositsViewModel } from './deposits.vm'
import { Input } from '../../../components/forms/input'

export function Deposits() {
  const { depositAmount, depositFixed, validation } = useDepositsViewModel()

  return <div className={styles.accountContainer}>
    <h2 className={styles.subHeading}>Deposit</h2>

    <div className={styles.fixedDeposits}>
      <ul className={styles.fixedDeposit}>
        <li><button aria-label="Deposit £5" onClick={() => depositFixed(5)}>£5</button></li>
        <li><button aria-label="Deposit £10" onClick={() => depositFixed(10)}>£10</button></li>
        <li><button aria-label="Deposit £20" onClick={() => depositFixed(20)}>£20</button></li>
        <li><button aria-label="Deposit £50" onClick={() => depositFixed(50)}>£50</button></li>
      </ul>
    </div>

    <form className={styles.amountForm} onSubmit={depositAmount}>
      <Input<DepositAmountForm> aria-label="Deposit Amount" label="Amount" type="number" step="any" placeholder="e.g. £25" {...validation.register()} errors={validation.errors} />
      <button type="submit">Deposit</button>
    </form>
  </div>
}
import { useCallback } from 'react'
import { DateTime } from 'luxon'
import { useGet } from '../utilities/http/use-authenticated-api'
import routes from './routes'
import { IsoDateTime, Maybe, Money } from '../global'
import { Game } from '../features/game/game.types'

type GameResponse = { offering: Offering, matchdays: { requested: Matchday }, player: Player }
type Offering = { players: number, first: Money, second: Money, third: Money, fourth: Money, fifth: Money, entries: number, nextIncrease: number }
type Matchday = { id: string, ordinal: number, start: IsoDateTime, end: IsoDateTime, fixtures: Array<Fixture>, isPlaying: boolean }
type Fixture = { reference: number, kickOff: IsoDateTime, homeTeam: Team, awayTeam: Team, postponed: boolean }
type Team = { name: string, shortName: string, bonus: Maybe<number> }
type Player = { isPlaying: boolean, predictions: Prediction[], standings: Standing[], history: Entry[] }
type Prediction = { fixture: number, home: number, away: number }
type Standing = { position: Maybe<number>, username: string, points: Maybe<number>, bonus: Maybe<number>, total: Maybe<number>, results: Result[] }
type Result = { fixture: number, points: number, bonus: number, home: TeamResult, away: TeamResult }
type TeamResult = { name: string, shortName: string, score: number, prediction: number }
type Entry = { id: string, ordinal: number, start: IsoDateTime, end: IsoDateTime }

export function useGetGameRequest() {
  const get = useGet()
  return useCallback(async (gameId?: string): Promise<Game> => {
    const game = await get<GameResponse>(routes.game(gameId))
    return {
      offering: { ...game.offering },
      matchday: {
        id: game.matchdays.requested.id,
        ordinal: game.matchdays.requested.ordinal,
        stake: 5.00,
        start: DateTime.fromISO(game.matchdays.requested.start),
        end: DateTime.fromISO(game.matchdays.requested.end),
        fixtures: game.matchdays.requested.fixtures
          .map(f => ({
            reference: f.reference,
            kickOff: DateTime.fromISO(f.kickOff),
            postponed: f.postponed,
            justSaved: false,
            homeTeam: {
              side: 'home',
              name: f.homeTeam.name,
              shortName: f.homeTeam.shortName,
              bonus: f.homeTeam.bonus ?? undefined,
              prediction: game.player.predictions.find(x => x.fixture === f.reference)?.home ?? 0
            },
            awayTeam: {
              side: 'away',
              name: f.awayTeam.name,
              shortName: f.awayTeam.shortName,
              bonus: f.awayTeam.bonus ?? undefined,
              prediction: game.player.predictions.find(x => x.fixture === f.reference)?.away ?? 0
            }
          }))
      },
      standings: game.player.standings,
      player: { isPlaying: game.player.isPlaying, history: game.player.history.map(x => ({ id: x.id, ordinal: x.ordinal, start: DateTime.fromISO(x.start), end: DateTime.fromISO(x.end) })) },
    }
  }, [get])
}



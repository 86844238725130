import controlStyles from '../../components/forms/forms.module.css'
import signupStyles from './signup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react'
import { v4 as uniqueId } from 'uuid'
import { UseFormReturn } from 'react-hook-form'
import { ErrorMessage } from '../../components/forms/error-message'
import { SignupForm } from './signup.vm'
import { Input } from './input'
import { Visitor, useUserStore } from '../../components/page/user.store'

const styles = { ...controlStyles, ...signupStyles }
const name = 'password'

export function Account({ form }: { form: UseFormReturn<SignupForm, object> }) {
  const { register, formState: { errors } } = form
  const user = useUserStore()

  return <>
    <Input label="Promo Code" defaultValue={(user as Visitor).referrer ?? ''} maxLength={20} errors={errors} {...register("promoCode")} />
    <Input label="Username" maxLength={20} errors={errors} {...register("username", { required: "Please enter your username" })} />
    <Password form={form} />
  </>
}

function Password({ form }: { form: UseFormReturn<SignupForm, object> }) {
  const { register, watch, formState: { errors } } = form
  const id = useRef<string>(uniqueId())
  return <>
    <div className={styles.controlContainer}>
      <label htmlFor={id.current}>Password</label>
      <ErrorMessage error={errors[name]?.message} />
      <input id={id.current} maxLength={70} type="password" aria-invalid={!!errors[name]} {...register(name, {
        required: { value: true, message: "Please enter a strong password" },
        pattern: { value: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[^\w\d]).{8,}$/, message: "Please enter a strong password" }
      })} />
    </div>
    <PasswordStrengthInfo password={watch(name, '') ?? ''} />
  </>
}

function PasswordStrengthInfo({ password }: { password: string }) {
  return <div role='status' className={styles.passwordInfo} >
    <h3>Your password must contain:</h3>
    <ul>
      <PasswordRequirement requirement='A letter;' fulfilled={/[a-zA-Z]+/.test(password)}></PasswordRequirement>
      <PasswordRequirement requirement='A number;' fulfilled={/[0-9]+/.test(password)}></PasswordRequirement>
      <PasswordRequirement requirement='A special character;' fulfilled={/[^\w\d]+/.test(password)}></PasswordRequirement>
      <PasswordRequirement requirement='At least 8 characters.' fulfilled={password.length >= 8}></PasswordRequirement>
    </ul>
  </div>
}

function PasswordRequirement({ requirement, fulfilled }: { requirement: string, fulfilled: boolean }) {
  return <li role='checkbox' aria-checked={fulfilled} >
    <FontAwesomeIcon role='checkbox' fixedWidth icon={fulfilled ? faCheck : faXmark} />
    {requirement}
  </li>
}
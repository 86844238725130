import styles from './fixture.module.css'
import { Fixture as AFixture } from '../game.types'
import { Prediction } from './predictions/prediction'
import { Timer } from './timer'
import { Team } from './teams/team'
import { Versus } from './teams/versus'
import { useIsLocked } from './fixtures.vm'

export function Fixture({ fixture }: { fixture: AFixture }) {
  const isLocked = useIsLocked(fixture)

  return <>    
    <li className={styles.fixture}>
      <Timer fixture={fixture} />
      <Team team={fixture.homeTeam} disabled={isLocked} showBonus={!fixture.postponed}  />
      <Team team={fixture.awayTeam} disabled={isLocked} showBonus={!fixture.postponed}  />
      <Prediction fixture={fixture} side='home' disabled={isLocked} />
      <Versus justSaved={fixture.justSaved} disabled={isLocked} />
      <Prediction fixture={fixture} side='away' disabled={isLocked} />
    </li>
    <li className={styles.spacer}>&nbsp;</li>
  </>
}
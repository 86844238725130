import { useNavigate } from "react-router-dom"
import { Registree, useRefreshAccount, useUserStore } from "../../../components/page/user.store"
import { usePurchaseTokensRequest } from "../../../api/purchase-tokens"
import { useMemo, useState } from "react"
import { Path } from '../../../paths'
import { formatMoney } from '../../../global'

type Prompt = { show: boolean, title: string, message: string, additionalMessages: string[], confirm: () => Promise<void>, cancel?: () => void }
const noPrompt: Prompt = { show: false, title: 'No prompt', message: '', additionalMessages: [], confirm: async () => { } }

export function usePromotionViewModel() {
  const goTo = useNavigate()
  const refreshAccount = useRefreshAccount()
  const purchaseTokens = usePurchaseTokensRequest()
  const user = useUserStore()

  const promptMessage = useMemo(() => ((user as Registree)?.balance?.cash >= 20
    ? `£20 will be deducted from your account. Your remaining balance will be ${formatMoney((user as Registree).balance.cash - 20)}.`
    : `There are not enough funds in your account to cover the £20 fee. Please make a deposit.`),
    [user])

  const purchasePrompt: Prompt = useMemo(() => ({
    show: true,
    title: 'Confirm Purchase!',
    message: promptMessage,
    additionalMessages: [],
    confirm: async () => {
      if ((user as Registree).balance.cash >= 20) {
        await purchaseTokens()
        setPrompt(noPrompt)
        await refreshAccount()
      }
      else {
        setPrompt(noPrompt)
        goTo(Path.account)
      }
    },
    cancel: () => {
      setPrompt(noPrompt)
    }
  }), [promptMessage, user, purchaseTokens, refreshAccount, goTo])

  const [prompt, setPrompt] = useState<Prompt>(noPrompt)

  async function attemptPurchase() {
    setPrompt(purchasePrompt)
  }

  function showPromos() {
    return window.togglesOn && user.signedIn && user.promotions.some(_ => true)
  }
  return { user, attemptPurchase, prompt, showPromos }
}
import { Fixture } from '../../game/game.types'
import { useMatchdayStore } from '../game.store'
import { useGameViewModel } from '../game.vm'
import { useFiveMinuteTimer } from '../../../hooks/use-five-minute-timer'
import { useDateDisplay } from '../../../hooks/use-date-display'
import { useState } from 'react'
import { useUserStore } from '../../../components/page/user.store'

export function useFixturesViewModel() {
  const matchday = useMatchdayStore()
  const dateDisplay = useDateDisplay()
  const user = useUserStore()
  const [dialogueClosing, setDialogueClosing] = useState<boolean>(false)
  const [userHasGivenFocus, setUserHasGivenFocus] = useState<boolean>(false)

  return {
    dates: dateDisplay(matchday.start, matchday.end), fixtures: matchday.fixtures ?? [],
    onFixtureFocus() {
      if (dialogueClosing) {
        setUserHasGivenFocus(false)
        setDialogueClosing(false)
      }
      else {
        setUserHasGivenFocus(true);
      }
    },
    requiresSignIn: {
      show: !user.signedIn && !dialogueClosing && userHasGivenFocus,
      onClose() { setDialogueClosing(true) }
    }
  }
}

export function useIsLocked(fixture: Fixture) {
  const { entryClosed, isPlaying } = useGameViewModel()
  const timer = useFiveMinuteTimer(fixture.kickOff)
  return (entryClosed && !isPlaying) || timer.expired || fixture.postponed
}
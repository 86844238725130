import { Input } from '../../../components/forms/input'
import styles from '../account.module.css'
import { useWithdrawalsViewModel, WithdrawalAmountForm } from './withdrawals.vm'

export function Withdrawal() {
  const { withdraw, validation } = useWithdrawalsViewModel()

  return <div className={styles.accountContainer}>
      <h2 className={styles.subHeading}>Withdrawal</h2>

      <form className={styles.amountForm} onSubmit={withdraw}>
        <Input<WithdrawalAmountForm> aria-label="Withdrawal Amount" label="Amount" type="number" step="any" placeholder="e.g. £25" {...validation.register()} errors={validation.errors} />
        <button type="submit">Withdraw</button>
      </form>
    </div>
}
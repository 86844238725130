import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useGameViewModel } from './game.vm';
import { Fixtures } from './fixtures/fixtures';
import { CurrentStandings } from './standings/standings';
import { Page } from '../../components/page/page';
import { useLoadGameFor } from './game.store';
import styles from './game.module.css'
import { Container } from '../../components/layout/container';
import { NavLink } from 'react-router-dom';
import { Path } from '../../paths';
import { useStandingsViewModel } from './standings/standings.vm';
import { useEffect } from 'react';
import { useEnterGameViewModel } from './enter.vm';
import { Button } from '../../components/buttons/buttons';
import { Dialogue } from '../../components/dialogue/dialogue';
import React from 'react';
import { SignInOrSignUp } from './signin-or-signup';
import { InfoPane } from './info-pane/info-pane';
import { Title } from '../../components/title/title';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useDateDisplay } from '../../hooks/use-date-display';
import { Offering as OfferingView } from './offering/offering';
import { Games } from './games';
import { HowItWorks } from './rules/how-it-works/how-it-works'
import { useOfferingViewModel } from './offering/offering.vm';

export function Game() {
  const gameId = useParams().id
  const gameIsLoading = useLoadGameFor(gameId)
  

  return <Page showWhen={!gameIsLoading}>
    <HowItWorks bonusPointText={Games['euro'].bonusPointText} prizeGrowthText={Games['euro'].prizeGrowthText} />
    <InfoPane />
    <MatchdayTitle />
    <Offering />
    <Tabs />
    <Outlet />
    <Offering />
  </Page>
}

export function Standings() {
  return <CurrentStandings />
}

function Offering() {
  const offering = useOfferingViewModel()
  return <OfferingView action={<Enter />} offering={offering} />
}

export function Predictions() {
  const { isOver } = useGameViewModel()
  const navigate = useNavigate()

  useEffect(() => {
    if (isOver) navigate('../standings');
  }, [isOver, navigate])

  return <Fixtures showTitle={false} />;
}

function Tabs() {
  const { standings } = useStandingsViewModel()
  const { isOver } = useGameViewModel()

  return <Container>
    <ul className={styles.tabs}>
      {!isOver ? <li><NavLink to={Path.predictions.relative}>Predictions</NavLink></li> : null}
      <li>{standings.length ? <NavLink to={Path.standings.relative}>Standings</NavLink> : <DisabledTab message='Will appear when the game starts'>Standings</DisabledTab>}</li>
      <li>{standings.length ? <NavLink to={Path.minileague.relative}>Mini League</NavLink> : <DisabledTab message='Will appear when the game starts'>Mini League</DisabledTab>}</li>
    </ul>
  </Container>
}

function DisabledTab({ children, message }: { children: string, message: string }) {
  return <span className={styles.disabledTab} title={message}>{children}</span>
}

function MatchdayTitle() {
  useTabSelector()
  const dateDisplay = useDateDisplay()
  const { start, end, matchday } = useGameViewModel()

  return <Title icon={faCalendar} title={dateDisplay(start, end)} matchday={matchday} />;
}

function useTabSelector() {
  const match = useMatch('/game/:id/:child')
  const navigate = useNavigate()
  const { entryClosed, isPlaying } = useGameViewModel()

  useEffect(() => {
    if (match === null) {
      if (entryClosed && isPlaying) navigate(Path.standings.relative)
      else navigate(Path.predictions.relative)
    }
  }, [entryClosed, isPlaying, match, navigate])
}

function Enter() {
  const { canEnter } = useGameViewModel()
  const { attemptEnter, entryPrompt, requiresSignIn } = useEnterGameViewModel()

  return canEnter ? <>
    <span>
      <Button onClick={() => attemptEnter('paid')}>Enter for £5</Button>
    </span>
    {entryPrompt.show ? <Dialogue title={entryPrompt.title} show={true} actions={entryPrompt.actions} onClose={entryPrompt.cancel}>
      {entryPrompt.message}
      <>{entryPrompt.additionalMessages.map(x => <React.Fragment key={x}>&nbsp;{x}</React.Fragment>)}</>
    </Dialogue> : null}
    {requiresSignIn.show ? <SignInOrSignUp onClose={requiresSignIn.onClose} /> : null}
  </> : null
}
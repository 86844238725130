import { useCallback, useState } from 'react'

type TimerHandle = ReturnType<typeof setTimeout>
export function useBurnout(duratrion: number, onDone: () => void) {
  const [handle, setHandle] = useState<TimerHandle | null>(null)

  const trigger = useCallback(() => {
    if (handle) clearTimeout(handle)

    const newHandle = setTimeout(onDone, duratrion)
    setHandle(newHandle)

    return () => { if (handle) clearTimeout(handle) }
  }, [duratrion, handle, onDone])

  return trigger
}
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { ProblemHandler } from '../utilities/http/jsonApi'
import { usePost } from '../utilities/http/use-authenticated-api'
import routes from './routes'

export function useSignupRequest() {
  const post = usePost()
  return useCallback(async (payload: SignupPayload, problemHandler?: ProblemHandler): Promise<void> => {
    const { dateOfBirth, ...everythingElse } = payload
    await post(routes.account, { ...everythingElse, dateOfBirth: dateOfBirth.toISODate() }, problemHandler)
  }, [post])
}

export type SignupPayload = {
  firstname: string
  surname: string
  dateOfBirth: DateTime
  email: string
  mobile: string
  postcode: string
  house: string
  addressLine1: string
  addressLine2: string
  townOrCity: string
  county: string
  username: string
  password: string
  promoCode: string
}
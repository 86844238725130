import { useIsLargeScreen } from '../../hooks/use-media-query'
import { Players } from './players'
import styles from './standings.module.css'

import { Standing } from './types'

export function Standings({ standings }: { standings: Standing[] }) {
  const isLargeScreen = useIsLargeScreen()
  if (standings.length === 0) return <></>

  return <table className={styles.standings}>
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th className={styles.standingsPlayer}>Player</th>
        {
          isLargeScreen ?
            <><th className={styles.standingsPoints}>Points</th><th className={styles.standingsPoints}>Bonus</th><th className={styles.standingsPoints}>Total</th></> :
            <><th className={styles.standingsPoints}>Pts</th><th className={styles.standingsPoints}>Bns</th><th className={styles.standingsPoints}>Ttl</th></>
        }
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      {standings.map(x => <Players player={x} key={`${x.username}-totals`} />)}
    </tbody>
  </table>
}
import cx from 'classnames'

import { useIsLargeScreen } from '../../hooks/use-media-query'

import styles from './standings.module.css'
import { Result as ResultData } from './types'

export function Result({ result }: { result: ResultData }) {
  const largeScreen = useIsLargeScreen()

  return <tr className={styles.resultsRow}>
    <td>{result.home.prediction} : {result.away.prediction}</td>
    <td className={cx(styles.team, styles.home)}>{largeScreen ? result.home.name : result.home.shortName}</td>
    <td className={styles.score}>{result.home.score} : {result.away.score}</td>
    <td className={cx(styles.team, styles.away)}>{largeScreen ? result.away.name : result.away.shortName}</td>
    <td>{result.points}</td>
    <td>{result.bonus}</td>
    <td colSpan={2}>{result.points + result.bonus}</td>
  </tr>
}
import { Standing } from './types';
import styles from './standings.module.css'
import { Result } from './result';

export function Results({ player, onClick }: { player: Standing, onClick: () => void }) {
  return <tr onClick={onClick}>
    <td colSpan={8} className={styles.resultsCell}>
      <table className={styles.results}>
        <thead>
          <tr>
            <th>Predicted</th>
            <th className={styles.result} colSpan={3}></th>
            <th>Points</th>
            <th>Bonus</th>
            <th colSpan={2}>Total</th>
          </tr>
        </thead>
        <tbody>
          {player.results.map(x => <Result result={x} key={`${player.username}-${x.fixture}`} />)}
        </tbody>
      </table>
    </td>
  </tr>
}
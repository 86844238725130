import { useCallback } from "react"
import { useGet } from "../utilities/http/use-authenticated-api"
import routes from "./routes"
import { Standing } from '../components/standings'

export function useGetStandings() {
    const get = useGet()
    return useCallback(async (game: string)  : Promise<Standing[]> => {
        return await get<Standing[]>(routes.standings(game))
    },[get])
}
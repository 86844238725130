import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Path } from '../../../paths'
import { Registree, useRefreshAccount, useUserStore } from '../../../components/page/user.store'
import { useTakeDepositRequest } from '../../../api/take-deposit'
import { Dialogue } from '../../../components/dialogue/dialogue'
import { Page } from '../../../components/page/page'

export function Deposited() {
  return <Page><DepositedContents /></Page>
}

function DepositedContents() {
  const [params] = useSearchParams()
  const { balance } = useUserStore() as Registree
  const refreshAccount = useRefreshAccount()
  const takeDeposit = useTakeDepositRequest()
  const [showUnapproved, setShowUnapproved] = useState<boolean>(false)

  useEffect(() => {
    (async function () {
      switch (params.get('ppp_status')) {
        case 'OK':
          await takeDeposit({ id: params.get('PPP_TransactionID')!, user: params.get('productId')!, amount: parseFloat(params.get('totalAmount')!), timestamp: params.get('responseTimeStamp')!, checksum: params.get('advanceResponseChecksum')! })
          await refreshAccount()
          window.location.assign(`/#${Path.dashboard}`)
          break
        case 'FAIL':
          setShowUnapproved(true)
          clearSearchParams()
          break
      }
    })()
  }, [params, takeDeposit, refreshAccount])

  return <Dialogue title="Unapproved Deposit" show={showUnapproved} actions={[{label:'OK', action:() => { setShowUnapproved(false); window.location.assign(`/#${Path.account}`) }}]}>{`Your deposit was not approved. Your balance remains at £${balance}.`}</Dialogue>
}

function clearSearchParams() {
  window.location.assign(`/#${Path.deposited}`)
}
import Arsenal from '../../../../images/shirts/arsenal.png'
import Villa from '../../../../images/shirts/astonvilla.png'
import Brentford from '../../../../images/shirts/brentford.png'
import Brighton from '../../../../images/shirts/Brighton.png'
import Bournemouth from '../../../../images/shirts/bournemouth.png'
import Burnley from '../../../../images/shirts/burnley.png'
import Chelsea from '../../../../images/shirts/chelsea.png'
import Palace from '../../../../images/shirts/crystalpalace.png'
import Everton from '../../../../images/shirts/everton.png'
import Liverpool from '../../../../images/shirts/liverpool.png'
import Luton from '../../../../images/shirts/luton.png'
import ManCity from '../../../../images/shirts/mancity.png'
import ManUtd from '../../../../images/shirts/manunited.png'
import Newcastle from '../../../../images/shirts/newcastle.png'
import Forest from '../../../../images/shirts/NottinghamForest.png'
import Sheffield from '../../../../images/shirts/sheffieldutd.png'
import Spurs from '../../../../images/shirts/tottenham.png'
import Fulham from '../../../../images/shirts/fulham.png'
import WestHam from '../../../../images/shirts/westham.png'
import Wolves from '../../../../images/shirts/wolves.png'
import Southampton from '../../../../images/shirts/southampton.png'
import Ipswich from '../../../../images/shirts/Ipswich.png'
import Leicester from '../../../../images/shirts/leicester.png'

const shirts: Record<string, string> = {
  'Arsenal': Arsenal,
  'Villa': Villa,
  'Brentford': Brentford,
  'Brighton': Brighton,
  'Bournemouth': Bournemouth,
  'Burnley': Burnley,
  'Chelsea': Chelsea,
  'Palace': Palace,
  'Everton': Everton,
  'Luton': Luton,
  'Liverpool': Liverpool,
  'ManCity': ManCity,
  'ManUtd': ManUtd,
  'Newcastle': Newcastle,
  'Forest': Forest,
  'SheffUtd': Sheffield,
  'Spurs': Spurs,
  'Fulham': Fulham,
  'WestHam': WestHam,
  'Wolves': Wolves,
  'Southampton': Southampton,
  'Ipswich': Ipswich,
  'Leicester': Leicester
}

export default shirts
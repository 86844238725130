import { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'

export type FiveMinuteTimer = { isTicking: false, expired: boolean } | { isTicking: true, expired: false, timeLeft: string }

export function useFiveMinuteTimer(target: DateTime): FiveMinuteTimer {
  const [timer, setTimer] = useState<FiveMinuteTimer>(CalculateTimer(target))
  useInterval(() => { setTimer(CalculateTimer(target)) }, 250)
  return timer
}

function CalculateTimer(target: DateTime): FiveMinuteTimer {
  const now = DateTime.now()
  const expired = now >= target.minus({ minutes: 5 })

  if (!target.hasSame(now, 'day') || expired)
    return { isTicking: false, expired: expired }

  return { isTicking: true, expired: false, timeLeft: getTimeLeft(target) }
}

function getTimeLeft(target: DateTime) {
  const timeLeft = target.minus({ minutes: 5 }).diff(DateTime.now(), ['hours', 'minutes', 'seconds'])

  const hours = timeLeft.hours?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })
  const minutes = timeLeft.minutes?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })
  const seconds = timeLeft.seconds?.toLocaleString('en-GB', { minimumIntegerDigits: 2, maximumFractionDigits: 0 })

  return `${hours}:${minutes}:${seconds}`
}

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>()

  useEffect(() => { savedCallback.current = callback; }, [callback])

  useEffect(() => {
    function tick() { if (savedCallback.current) savedCallback.current() }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay]);
}
import { useEffect, useState } from 'react';
import { useGetMiniLeague } from '../../../api/get-mini-league';
import { useParams } from 'react-router-dom';
import { Standing } from '../game.types';

export function useMiniLeague() {
  const { id } = useParams()
  const [miniLeague, setMiniLeague] = useState<Standing[]>([])
  const getMiniLeague = useGetMiniLeague()

  useEffect(() => {
    (async () => { setMiniLeague(await getMiniLeague(id as string)) })()
  },[getMiniLeague, id])

  return miniLeague
}
import styles from './team.module.css'
import cx from 'classnames'
import shirts from './shirts-pl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { useIsLargeScreen } from '../../../../hooks/use-media-query'
import { Team as ATeam } from '../../game.types'

export function Team({ team, disabled, showBonus }: { team: ATeam, disabled: boolean, showBonus: boolean }) {
  const largeScreen = useIsLargeScreen()

  return <span className={cx({ [styles.homeTeam]: team.side === 'home', [styles.awayTeam]: team.side === 'away', [styles.teamDisabled]: disabled })}>
    <Shirt team={team} />
    <span className={styles.teamName} title={team.name}>{largeScreen ? team.name : team.shortName}</span>
    {showBonus ? <BonusPoints team={team} /> : null}
  </span>
}

function BonusPoints({ team }: { team: ATeam }) {
  if (!team.bonus) return null

  return <span className={styles.bonusPoints} title={`${team.name} bonus`}>
    {team.side === 'away' ? <FontAwesomeIcon icon={faStar} /> : null}
    {team.bonus}
    {team.side === 'home' ? <FontAwesomeIcon icon={faStar} /> : null}
  </span>
}

function Shirt({ team }: { team: ATeam }) {  
  const shirt = shirts[team.shortName.replace(/\s/g, '')] 
  return <img className={styles.shirt} src={shirt} alt={team.name} />
}
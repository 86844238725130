import { Visitor, useUserStore } from '../../components/page/user.store'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../paths'
import { Action, LabelledAction } from '../../components/dialogue/dialogue'
import { Dialogue } from '../../components/dialogue/dialogue'

export function SignInOrSignUp({ onClose }: { onClose: Action }) {
    const user = useUserStore()
    const goTo = useNavigate()

    const actions: LabelledAction[] = [{
        label: 'Sign In', action: async () => {
            (user as Visitor).signIn()
        }
    },
    {
        label: 'Sign Up', action: async () => {
            goTo(Path.signup)
        }
    }]

    return <Dialogue title={'Sign In or Sign Up'} show={true} actions={actions} onClose={onClose} showCancel={false} >
        {'You need to be signed in to play our games.'}
    </Dialogue>
}

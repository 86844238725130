import { Page } from '../../components/page/page'
import { GamesList } from './games-list/games-list'
import { Promotions } from './promotions/promotions'
import { Banner } from '../game/social/social'
import { Container } from '../../components/layout/container'

export function Dashboard() {  
  return <Page>    
    <Promotions />
    { window.togglesOn ? <Container><Banner /></Container> : null }
    <GamesList />
  </Page>
}

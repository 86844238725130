import controlStyles from '../../components/forms/forms.module.css'
import datepickerStyles from './signup.module.css'
import { DateTime } from 'luxon'
import { UseFormReturn } from 'react-hook-form'
import { SignupForm } from './signup.vm'
import { ErrorMessage } from '../../components/forms/error-message'
import eighteenPlus from '../../images/18+.png'
const styles = { ...controlStyles, ...datepickerStyles }

const dobRequired = 'Please enter your date of birth'
export function DateOfBirth({ form }: { form: UseFormReturn<SignupForm, object> }) {
  const { register, formState: { errors } } = form
  return <>
    <div className={styles.controlContainer}>
      <label htmlFor="day">Date of Birth</label>
      {errors.dobDay ? <ErrorMessage error={errors.dobDay.message} /> : errors.dobMonth ? <ErrorMessage error={errors.dobMonth.message} /> : errors.dobYear ? <ErrorMessage error={errors.dobYear.message} /> : null}
      <div className={styles.dateOfBirth}>
        <select id="day" aria-label='Day' aria-invalid={!!errors.dobDay} {...register('dobDay', { required: dobRequired })}>
          <option value="">Day</option>
          <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option>
          <option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option>
          <option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option>
          <option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option>
          <option value="21">21</option><option value="22">22</option><option value="23">23</option><option value="24">24</option><option value="25">25</option>
          <option value="26">26</option><option value="27">27</option><option value="28">28</option><option value="29">29</option><option value="30">30</option>
          <option value="31">31</option>
        </select>
        <select aria-label='Month' aria-invalid={!!errors.dobMonth} {...register('dobMonth', { required: dobRequired })}>
          <option value="">Month</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
        <select aria-label='Year' aria-invalid={!!errors.dobYear} {...register('dobYear', { required: dobRequired })}>
          <option value="">Year</option>
          {yearsFrom1900To18YearsAgo().map((year) => <option key={year} value={year}>{year}</option>)}
        </select>
        <img src={eighteenPlus} alt="18+" />
      </div>
    </div>
  </>
}

function yearsFrom1900To18YearsAgo() {
  const year120Ago = DateTime.now().minus({ years: 120 }).year
  const noOfYears = 120 - 18
  const years = []
  for (let i = 0; i <= noOfYears; i++)
    years.unshift((year120Ago + i).toString())

  return years
}
import styles from './how-it-works.module.css'
import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faChevronDown, faChevronUp, faStar, faSquare } from '@fortawesome/free-solid-svg-icons'
import { useIsLargeScreen } from '../../../../hooks/use-media-query'
import { Panel } from '../../../../components/layout/panel'
import { useToggle } from 'react-use'

export function HowItWorks({ alwaysOpen = false, bonusPointText, prizeGrowthText }: { alwaysOpen?: boolean, bonusPointText: string, prizeGrowthText: string[] }) {
  const largeScreen = useIsLargeScreen()
  const [isOpen, toggle] = useToggle(false)
  return <Panel>
    <div onClick={toggle} className={styles.toggle}>
      <FontAwesomeIcon icon={faInfoCircle} />
      <span>Rules</span>
      {!alwaysOpen ? <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} /> : null}
    </div>
    {isOpen || alwaysOpen ? <div className={styles.howItWorks}>
      <Gameplay />
      <ScoringSystem />
      {largeScreen ? <><PotGrowth text={prizeGrowthText} /><BonusPoints text={bonusPointText} /></> : <><BonusPoints text={bonusPointText} /><PotGrowth text={prizeGrowthText} /></>}
    </div> : <></>}
  </Panel>
}

function Gameplay() {
  return <ul>
    <li>
      <h3>Gameplay</h3>
    </li>
    <Item type='up-bullet'>Game entry closes 5 minutes before the first fixture starts</Item>
    <Item type='up-bullet'>Predictions can be changed up to 5 mins before kick off</Item>
    <Item type='up-bullet'>Standings are available when the first fixture starts</Item>
    <Item type='up-bullet'>Postponed fixtures are excluded from the game</Item>
    <Item type='up-bullet'>The game will be void if 4 or more fixtures are postponed</Item>
    <Item type='up-bullet'>Points are awarded for either the FT or AET score excluding penaties.</Item>
    <Item type='up-bullet'>Your points and  position in the standings are updated in realtime</Item>
  </ul>
}

function ScoringSystem() {
  return <ul>
    <li>
      <h3>Scoring System</h3>
    </li>
    <Item type='up-bullet' points={15}>Perfect score</Item>
    <Item type='up-bullet' points={10}>Correct result &amp; one correct score</Item>
    <Item type='up-bullet' points={8}>Correct result</Item>
    <Item type='up-bullet' points={3}>Incorrect result &amp; one correct score</Item>
  </ul>
}

function BonusPoints({ text }: { text: string }) {
  return <ul>
    <li>
      <h3>Bonus Points</h3>
    </li>
    <Item type='up-star'>{text}</Item>
  </ul>
}

function PotGrowth({ text }: { text: string[] }) {
  return <ul>
    <li><h3>Pot Growth</h3></li>
    {text.map(x => <Item type='up-bullet' key={x}>{x}</Item>)}    
  </ul>
}

type BulletType = 'up-bullet' | 'up-star'
function Bullet({ type }: { type: BulletType }) {
  return type === 'up-star' ? <FontAwesomeIcon fixedWidth icon={faStar} /> : <FontAwesomeIcon fixedWidth icon={faSquare} />
}

function Item({ children, type, points }: { children: ReactNode, type: BulletType, points?: number }) {
  return <li className={styles.item}>
    <Bullet type={type} />
    <span className={styles.itemText}>{children}{points !== undefined ? <span className={styles.points}>&nbsp;{points}pts</span> : <></>}</span>
  </li>
}
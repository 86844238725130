import React, { useMemo } from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'
import { v4 as uniqueId } from 'uuid'
import { ErrorMessage } from './error-message'
import styles from './forms.module.css'

declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

type InputProps<T extends FieldValues> = {
  label: string,
  name: Extract<keyof T, string>,
  errors: FieldErrors<T>,
} & Omit<JSX.IntrinsicElements["input"], "name">

function InputInner<T extends FieldValues>({ label, errors, id, maxLength, ...props }: InputProps<T>, ref: React.ForwardedRef<HTMLInputElement>) {
  const inputId = useMemo(() => id ?? uniqueId(), [id])

  return <div className={styles.controlContainer}>
    <label htmlFor={inputId}>{label}</label>
    <ErrorMessage error={errors[props.name]?.message as string} />
    <div className={styles.inputContainer}>
      <input id={inputId} maxLength={maxLength ?? 70} aria-invalid={!!errors[props.name]} {...props} ref={ref} />
    </div>
  </div>
}

export const Input = React.forwardRef(InputInner)
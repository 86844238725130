import cx from 'classnames'
import { Fixture, Side } from '../../game.types'
import { usePredictionsViewModel } from './predictions.vm'
import styles from './prediction.module.css'

export function Prediction({ fixture, side, disabled }: { fixture: Fixture, side: Side, disabled: boolean }) {
  const team = side === 'home' ? fixture.homeTeam : fixture.awayTeam
  const { onInput, prediction, ref } = usePredictionsViewModel(fixture.reference, team)

  return <input
    className={cx(styles.prediction, { [styles.home]: side === 'home', [styles.away]: side === 'away', [styles.predictionDisabled]: disabled })}
    ref={ref}
    value={fixture.postponed ? '': prediction} // todo - put p back in for postponed
    aria-label={team.name}
    onInput={onInput}
    onFocus={e => e.currentTarget.setSelectionRange(0, 0)}
    inputMode='numeric'
    pattern='[0-9]'
    disabled={disabled}
  />
}


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './social.module.css'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FacebookShareButton, WhatsappShareButton, EmailShareButton } from 'react-share'
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Registree, useUserStore } from '../../../components/page/user.store'
import { Urls } from '../../../paths'

type BannerProps = { text?: string, icon?: IconDefinition }

export function Banner({ text, icon }: BannerProps) {
  const url = useSharingUrl()
  const socialMessage = useSocialMessage()
  const emailText = useEmailText()
  const hasLegend = !!text && !!icon

  return <div className={styles.container}>
    <div className={styles.sharing}>
      <span className={styles.label}>Invite friends:</span>
      <WhatsappShareButton url={socialMessage}>
        <Stack icon={faWhatsapp} colour='#25d365' />
      </WhatsappShareButton>
      <EmailShareButton url={url} subject='Join me at uPredict' body={emailText}>
        <Stack icon={faEnvelope} colour='#7f7f7f' />
      </EmailShareButton>
      <FacebookShareButton url={url}>
        <Stack icon={faFacebook} colour='#3b5898' />
      </FacebookShareButton>
    </div>

    {hasLegend ? <div className={styles.legend}>
      {text}<FontAwesomeIcon icon={icon!} />
    </div> : null}

  </div>
}

export function ShareMe() {
  const url = useSharingUrl()
  const socialMessage = useSocialMessage()
  const emailText = useEmailText()

  return <div className={styles.shareMe}>
    <WhatsappShareButton url={socialMessage}>
      <Stack icon={faWhatsapp} colour='#25d365' />
    </WhatsappShareButton>
    <EmailShareButton url={url} subject='Join me at uPredict' body={emailText}>
      <Stack icon={faEnvelope} colour='#7f7f7f' />
    </EmailShareButton>
    <FacebookShareButton url={url}>
      <Stack icon={faFacebook} colour='#3b5898' />
    </FacebookShareButton>
  </div>
}

function useEmailText() {
  const user = useUserStore()

  return user.signedIn ?
    `Hi,\n\n
    I'm inviting you to join my uPredict mini-league. It's a fun way to predict match outcomes and compete in cash games.\n\n
    
    Minimum pots of £250 for a £5 entry and that grow as more players join is the reason I play. Plus, free entries can be earned when you sign-up with my referral link below\n\n

    Let's make football even more exciting together! Join me today.

    \n\n${Urls.home(user.username)}`

    :

    `Hi,\n\n
    I'm inviting you to join uPredict. It's a fun way to predict match outcomes and compete in cash games.\n\n
    
    Minimum pots of £250 for a £5 entry and that grow as more players join is the reason I play. Plus, free entries can be earned when you sign-up:\n\n

    Let's make football even more exciting together! Join me today.`
}

function useSocialMessage() {
  const user = useUserStore()

  return user.signedIn ?
    `Join my uPredict mini-league! Cash games with a minimum £250 pot are available. Free entries can be earned when you sign-up with this link:\n\n${Urls.home(user.username)}` :
    `Come and join uPredict. \n\nCash games with a minimum £250 pot are available. Free entries can be earned when you sign-up:\n\n${Urls.home()}`
}

function useSharingUrl() {
  const { username } = useUserStore() as Registree
  return Urls.home(username)
}

function Stack({ icon, colour }: { icon: IconDefinition, colour: string }) {
  return <span className={styles.iconContainer} style={{ backgroundColor: colour }}>
    <FontAwesomeIcon icon={icon} fixedWidth />
  </span>
}

import styles from './home.module.css'
import flexible from '../../images/svg/flexible-gameplay.svg'
import scoring from '../../images/svg/unique-scoring-system.svg'
import sportFans from '../../images/svg/fixed-pool-size.svg'

import { Page } from '../../components/page/page'
import { CallToAction } from '../../components/buttons/buttons'
import { Path } from '../../paths'
import { useUserStore } from '../../components/page/user.store'
import { Navigate } from 'react-router-dom'

export function Home() {
  return <Page>
    <PageContents />
  </Page>
}

function PageContents() {
  const user = useUserStore()
  return user.signedIn ? <Navigate to={Path.dashboard} /> : <>   
    
    <div className={styles.blurbOuter}>
      <main className={styles.blurb}>
        <section>
          <img src={flexible} alt="Don't just bet and hope for the best!" />
          <h5>Don't just bet and hope for the best!</h5>
          <p>It's not an ACCA. It's tactical. Make the right in game changes and you can still win.</p>
        </section>

        <section>
          <img src={scoring} alt='Will you play it safe or take a risk?' />
          <h5>Will you play it safe or take a risk?</h5>
          <p>Points for accuracy and bonuses for shocks. Bigger shocks mean bigger bonuses.</p>
        </section>

        <section>
          <img src={sportFans} alt='' />
          <h5>Will you rise to the top?</h5>
          <p>All games have a minimum pot. As the community grows so do the prizes!</p>
        </section>

        <span className={styles.joinNow}>     
          <CallToAction to={Path.signup}>SIGN UP</CallToAction>
        </span>
      </main>
    </div>
  </>
}

import { useCallback, ReactNode, useMemo } from 'react'
import createDescendantContext from '@chakra-ui/descendant'
import { useBurnout } from '../../../../hooks/use-burnout'
import { useChangePredictionRequest } from '../../../../api/change-prediction'
import { Fixture, Team } from '../../game.types'
import { useIsPlayingStore, useMatchdayStore, usePredictingStore } from '../../game.store'

const [PredictionsRefsProvider, , usePredictionRefs, usePredictionRef] = createDescendantContext()

export function PredictionRefs({ children }: { children: ReactNode }) {
  const refs = usePredictionRefs()
  return <PredictionsRefsProvider value={refs}>
    {children}
  </PredictionsRefsProvider>
}

export function usePredictionsViewModel(reference: number, team: Team) {
  const { index, register, descendants: predictions } = usePredictionRef()
  const matchday = useMatchdayStore()
  const isPlaying = useIsPlayingStore()
  const { makePrediction, clearSaveMessage } = usePredictingStore()
  const showSaveMessage = useBurnout(1000, () => { clearSaveMessage(reference) })
  const savePrediction = useChangePredictionRequest()
  const fixture = useMemo(() => matchday.fixtures.find(x => x.reference === reference)!, [matchday, reference])

  const onInput = useCallback(async (event: any) => {
    const action = processInput(event.nativeEvent.data)
    if (action.update) {
      if (isPlaying)
        savePrediction(matchday.id, getNewPrediction(fixture, team, action.newValue))

      makePrediction(reference, team, action.newValue, isPlaying)

      if (isPlaying)
        showSaveMessage()

      if (action.move) {
        const next = predictions.nextEnabled(index, true)
        next?.node.focus()
      }
    }
  }, [isPlaying, savePrediction, matchday.id, fixture, team, makePrediction, reference, showSaveMessage, predictions, index])

  const prediction = fixture[team.side === 'home' ? 'homeTeam' : 'awayTeam'].prediction
  return { prediction, ref: register, onInput }
}

function getNewPrediction(fixture: Fixture, team: Team, prediction: number) {
  let newPrediction = { reference: fixture.reference, home: fixture.homeTeam.prediction, away: fixture.awayTeam.prediction }
  if (team.side === 'home') newPrediction.home = prediction
  if (team.side === 'away') newPrediction.away = prediction
  return newPrediction
}

type Action = { update: false } | { update: true, move: boolean, newValue: number }
function processInput(input: string): Action {
  if (input === null || input === undefined) return { newValue: 0, move: false, update: true }
  if (digits.includes(input)) return { newValue: parseInt(input), move: true, update: true }

  return { update: false }
}

const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

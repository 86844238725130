import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { Fixture } from '../game.types'
import { useFiveMinuteTimer } from '../../../hooks/use-five-minute-timer'
import styles from './timer.module.css'

export function Timer({ fixture }: { fixture: Fixture }) {
  const timer = useFiveMinuteTimer(fixture.kickOff)
  return <div className={styles.status}>{timer.isTicking && !fixture.postponed ? 
      <div role='timer' aria-label={fixture.reference.toString()} ><FontAwesomeIcon icon={faStopwatch} />{timer.timeLeft}</div> : null  }</div>
}
import styles from './signup.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Panel } from '../../components/layout/panel'
import { Page } from '../../components/page/page'
import { Button } from '../../components/buttons/buttons'
import { Path } from '../../paths'
import { Dialogue } from '../../components/dialogue/dialogue'
import { useSignupViewModel } from './signup.vm'
import { SignupForm } from './signup.vm'
import { Personal } from './personal'
import { Account } from './account'
import { ErrorMessage } from '../../components/forms/error-message'
import { Address } from './address'

export function Signup() {
  const goTo = useNavigate()
  const { signup, errors, informOfVerification } = useSignupViewModel()
  const form = useForm<SignupForm>({ mode: "onTouched" })
  const { handleSubmit, formState: { isSubmitting } } = form

  return <Page>
    <Panel narrow>
      <form className={styles.signup} onSubmit={handleSubmit(signup)} noValidate>
        <h1>Sign Up</h1>

        <Personal form={form} />
        <Address form={form} />
        <Account form={form} />

        <TermsMessage />

        <Button type="submit" disabled={isSubmitting}>Sign Up</Button>
        <ErrorMessage error={errors.join('\r\n')} />
      </form>
      <Dialogue title='Please verify account!' show={informOfVerification} actions={[{label:'OK', action: () => goTo(Path.dashboard)}]}>Account verification is needed to play the game. Please follow the link in the email you have been sent. The email may be in your spam or junk folder.</Dialogue>
    </Panel>
  </Page >
}

function TermsMessage() {
  return <p className={styles.terms}>Please be aware that by signing up you are accepting our <Link to={Path.terms} target="_blank">Terms of Use</Link></p>
}
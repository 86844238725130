import styles from '../account.module.css'
import { DateTime } from 'luxon'
import { formatMoney } from '../../../global'
import { useTransactionHistoryViewModel } from './transaction-history.vm'

export function TransactionHistory() {
  const { transactions } = useTransactionHistoryViewModel()

  return <>{transactions && transactions.length ?
    <div className={styles.accountContainer}>
      <h2 className={styles.subHeading}>Transaction History</h2>

      <table className={styles.transactionHistory}>
        <thead>
          <tr>
            <th className={styles.date}>Date</th>
            <th></th>
            <th className={styles.amount}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => <tr key={index} aria-label="Transaction">
            <td className={styles.date} aria-label="Date">{toDate(transaction.time)}</td>
            <td className={styles.description} aria-label="Description"><div><div>{transaction.description}</div></div></td>
            <td className={styles.amount} aria-label="Transaction Amount">{formatMoney(transaction.amount)}</td>
          </tr>)}
        </tbody>
      </table>
    </div> : null}</>
}

function toDate(dateTime: DateTime) { return dateTime.toLocaleString({ ...DateTime.DATE_SHORT, year: '2-digit' }) }

import { useCallback } from "react"
import { useGet } from "../utilities/http/use-authenticated-api"
import routes from "./routes"
import { IsoDateTime } from '../global'
import { DateTime } from 'luxon'
import { Entry } from "../features/account/account.types"

type EntryResponse = { id: string, ordinal: number, start: IsoDateTime, end: IsoDateTime }

export function useGetRecentlyPlayed() {
    const get = useGet()
    return useCallback(async ()  : Promise<Entry[]> => {
        return (await get<EntryResponse[]>(routes.recentlyPlayed)).map(x=>({
            ...x,
            start: DateTime.fromISO(x.start),
            end: DateTime.fromISO(x.end)
        }))
    },[get])
}
import React from 'react'
import ReactDOM from 'react-dom'
import "@reach/dialog/styles.css"
import { Routes, Route, HashRouter, Navigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { enableAllPlugins } from 'immer'
import { createLogger } from './utilities/logging/logzio'
import { configure } from './utilities/logging/logging'
import { ErrorHandler } from './features/error-handling/error-handler'
import { PageTracker } from './features/home/page-tracker'
import { Path } from './paths'
import { Signup } from './features/signup/signup'
import './index.css'
import './utilities/prevent-auto-zoom'
import { Terms } from './features/footer/terms'
import { Privacy } from './features/footer/privacy'
import { Account } from './features/account/account'
import { Deposited } from './features/account/deposits/deposited'
import { Withdrawn } from './features/account/withdrawals/withdrawn'
import { Dashboard } from './features/dashboard/dashboard'
import { RecentlyPlayed } from './features/recently-played/recently-played'
import { Game, Standings, Predictions } from './features/game/game'
import { MiniLeague } from './features/game/mini-league/mini-league'
import { Toaster } from 'react-hot-toast'
import { Home } from './features/home/home'
import { Rules } from './features/game/rules/rules'

type Configuration = { logging: { url: string }, auth: { domain: string, clientId: string, audience: string }, getAddress: { apiKey: string } }
declare global {
    interface Window {
        configuration: Configuration
        togglesOn: boolean
    }
}

enableAllPlugins()
if (!/localhost/.test(window.location.href))
    configure(createLogger())

let redirectUri = new URL(window.location.origin)
redirectUri.hash = Path.dashboard

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <ErrorHandler>
                <PageTracker />                
                <Auth0Provider redirectUri={redirectUri.toString()} clientId={window.configuration.auth.clientId} domain={window.configuration.auth.domain} audience={window.configuration.auth.audience}>
                    <Routes>
                        <Route index path={Path.home} element={<Home />} />
                        <Route path={Path.dashboard} element={<Dashboard />} />
                        <Route path={Path.game(':id')} element={<Game />}>
                            <Route path={Path.predictions.relative} element={<Predictions />} />
                            <Route path={Path.standings.relative} element={<Standings />} />
                            <Route path={Path.minileague.relative} element={<MiniLeague />} />
                        </Route>
                        <Route path={Path.rules()} element={<Rules />} />
                        <Route path={Path.signup} element={<Signup />} />
                        <Route path={Path.account} element={<Account />} />
                        <Route path={Path.deposited} element={<Deposited />} />
                        <Route path={Path.withdrawn} element={<Withdrawn />} />
                        <Route path={Path.terms} element={<Terms />} />
                        <Route path={Path.privacy} element={<Privacy />} />
                        <Route path={Path.unknown} element={<Dashboard />} />
                        <Route path={Path.recentlyPlayed} element={<RecentlyPlayed />} />
                        <Route path="/toggles-on" element={<TogglesOn />} />
                    </Routes>
                </Auth0Provider>
            </ErrorHandler>
        </HashRouter>
        <Toaster />
    </React.StrictMode>,
    document.getElementById('react'))

function TogglesOn() {
    window.togglesOn = true
    return <Navigate to={Path.home} />
}
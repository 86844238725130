import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useToggle } from '../../hooks/use-toggle'
import styles from './entries.module.css'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Entry } from '../account/account.types'

export function Entries({ entries, selectedEntry, selectEntry }: { entries: Entry[], selectedEntry: Entry, selectEntry: (entry: Entry) => void }) {
  const [isOpen, toggle] = useToggle(false)

  return <div className={styles.entries}>    
      <button className={styles.trigger} type="button" onClick={toggle} role="combobox" aria-haspopup="listbox" aria-controls="games-list" aria-expanded={isOpen} aria-label="Recently Played">
        <span>Recently Played</span>
        <FontAwesomeIcon icon={faCaretDown} />
      </button>
      <div id="games-list" role="listbox" className={styles.options} hidden={!isOpen}>
        <ul>
          {entries.map(x => <li key={x.ordinal}>
            <button className={x.id === selectedEntry.id ? styles.selected : ''} role="option" aria-label="Recent" aria-selected="false" onClick={() => selectEntry(x)}>
              {entryDisplay(x)}
            </button></li>)}
        </ul>
      </div>
    </div>  
}

function entryDisplay(entry: Entry) {  
  return `${leagueText(entry)} ${entry.ordinal}: ${entry.start.toFormat('ccc dd LLL')}`
}

function leagueText(entry: Entry) : string {
  return entry.id.startsWith('4') ? 'Euros Game' : 'EPL Matchday'
}

import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '../../../components/layout/container'
import { PredictionRefs } from './predictions/predictions.vm'
import { useFixturesViewModel } from './fixtures.vm'
import { Fixture } from './fixture'
import styles from './fixture.module.css'
import { SignInOrSignUp } from '../signin-or-signup'
import { Banner } from '../social/social'

export function Fixtures({showTitle = true} : {showTitle? : boolean}) {
  const { dates, fixtures, onFixtureFocus, requiresSignIn } = useFixturesViewModel()
  
  return <Container>
    { showTitle ? 
      <h2 className={styles.heading}><FontAwesomeIcon icon={faCalendar} />{dates}<span className={styles.bonusPointsHeader}><FontAwesomeIcon icon={faStar} /> Bonus Points</span></h2>
      : <Banner text='Bonus Points' icon={faStar} /> }
    <PredictionRefs>
      <ul className={styles.fixtures} onFocus={onFixtureFocus}>{fixtures.map(x => <Fixture fixture={x} key={x.reference} />) }</ul>
    </PredictionRefs>
    {requiresSignIn.show ? <SignInOrSignUp onClose={requiresSignIn.onClose} /> : null }
  </Container>
}
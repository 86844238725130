import { useForm } from 'react-hook-form'
import { useGetWithdrawalPageUrlRequest } from '../../../api/get-withdrawal-page-url'
import { Registree, useUserStore } from '../../../components/page/user.store'

export type WithdrawalAmountForm = { amount: number }

export function useWithdrawalsViewModel() {
  const { balance } = useUserStore() as Registree
  const { handleSubmit, register, formState: { errors } } = useForm<WithdrawalAmountForm>({ mode: 'onTouched' })
  const getWithdrawalPage = useGetWithdrawalPageUrlRequest()

  async function redirect(amount: number) {
    window.location.href = await getWithdrawalPage(amount)
  }

  const validation = {
    required: 'Amount is required',
    min: { value: 10, message: 'Miniumum withdrawal: £10' },
    max: { value: balance.cash, message: `Maximum withdrawal: £${balance.cash}` },
    pattern: { value: /^\d*(\.\d{0,2})?$/, message: 'Must be a valid amount of money' }
  }

  return {
    withdraw: handleSubmit(form => redirect(form.amount)),
    validation: {
      register() { return register('amount', validation) },
      errors
    }
  }
}
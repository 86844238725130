import useFsmReducer from 'use-fsm-reducer';
import { Standing } from '../../components/standings';
import { useGetRecentlyPlayed } from '../../api/get-recently-played';
import { useGetStandings } from '../../api/get-standings';
import { Entry } from '../account/account.types';

type State =
  | { type: 'loading-entries' }
  | { type: 'loading-standings', entries: Entry[] }
  | { type: 'loaded', entries: Entry[], standings: Standing[], selectedEntry: Entry }

type Action =
  | { type: 'entires-loaded', entries: Entry[] }
  | { type: 'standings-loaded', standings: Standing[], selectedEntry: Entry }
  | { type: 'select-entry', entry: Entry }

type Effect =
  | { type: 'load-entries' }
  | { type: 'load-standings', entry: Entry }

export function useRecentlyPlayed() {
  const getRecentlyPlayed = useGetRecentlyPlayed()
  const getStandings = useGetStandings()

  const [state, dispatch] = useFsmReducer<State, Action, Effect>({
    initialState: { type: 'loading-entries' },
    runEffectsOnMount: [{ type: 'load-entries' }],
    states: {
      'loading-entries': {
        on: {
          'entires-loaded'(_, action) {
            return {
              type: 'loading-standings',
              entries: action.entries,
              effects: [{ type: 'load-standings', entry: action.entries[0] }]
            }
          }
        }
      },
      'loading-standings': {
        on: {
          'standings-loaded'(state, action) {
            return {
              type: 'loaded',
              entries: state.entries,
              standings: action.standings,
              selectedEntry: action.selectedEntry
            }
          }
        }
      },
      'loaded': {
        on: {
          'select-entry'(state, action) {
            return {
              type: 'loading-standings',
              entries: state.entries,
              effects: [{ type: 'load-standings', entry: action.entry }]
            }
          }
        }
      }
    },
    effects: {
      async 'load-entries'({ dispatch }) {
        const entries = await getRecentlyPlayed()
        dispatch({ type: 'entires-loaded', entries })
      },
      async 'load-standings'({ dispatch, effect }) {
        const standings = await getStandings(effect.entry.id)
        dispatch({ type: 'standings-loaded', standings, selectedEntry: effect.entry })
      }
    }
  })

  return {
    ...state,
    selectEntry(entry: Entry) { dispatch({ type: 'select-entry', entry }) }
  }
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '../../components/layout/container';
import { Page } from '../../components/page/page';
import { Standings } from '../../components/standings';
import { Entries } from './entries';
import { useRecentlyPlayed } from './recently-played.vm';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useDateDisplay } from '../../hooks/use-date-display';
import styles from './recently-played.module.css'
import { Entry } from '../account/account.types';

export function RecentlyPlayed() {
  const recentlyPlayed = useRecentlyPlayed()
  return <Page showWhen={recentlyPlayed.type === 'loaded'}>
    <Container>
      <Contents {...recentlyPlayed as ContentProps} />
    </Container>
  </Page>
}

type ContentProps = Omit<Extract<ReturnType<typeof useRecentlyPlayed>, { type: 'loaded' }>, 'effects' | 'type'>
function Contents({ entries, selectedEntry, selectEntry, standings }: ContentProps) {
  return <>
    <Entries entries={entries} selectedEntry={selectedEntry} selectEntry={selectEntry} />
    <Title entry={selectedEntry} />
    <Standings standings={standings} />
  </>
}

function Title({ entry } : { entry: Entry }) {
  const dateDisplay = useDateDisplay()
  return <h2 className={styles.heading}><FontAwesomeIcon icon={faCalendar} />{dateDisplay(entry.start, entry.end)}</h2>
}
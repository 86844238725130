import { DateTime } from 'luxon'
import { useFiveMinuteTimer } from '../../hooks/use-five-minute-timer'
import { useIsPlayingStore, useMatchdayStore } from './game.store'

export function useGameViewModel() {
    const matchday = useMatchdayStore()
    const isPlaying = useIsPlayingStore()
    const timer = useFiveMinuteTimer(matchday.start)
    const entryTimeLeft = timer.isTicking && !isPlaying ? timer.timeLeft : null
    const entryClosed = timer.expired 

    return { 
        isPlaying, 
        isOver: matchday.end < today(), 
        canEnter: !isPlaying && !entryClosed, 
        entryClosed, entryTimeLeft, 
        start: matchday.start, 
        end: matchday.end,
        matchday: matchday.id
    }
}

function today() {
    return DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}
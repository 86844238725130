import { useCallback } from 'react'
import { usePut } from '../utilities/http/use-authenticated-api'
import routes from './routes'
import { Prediction } from './payloads'

export function useChangePredictionRequest() {
  const put = usePut()
  return useCallback(async (game: string, prediction: Prediction): Promise<void> => {
      await put(routes.predict(game, prediction.reference), { home: prediction.home, away: prediction.away })
    }, [put])
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useToggle } from '../../hooks/use-toggle'
import { Registree, useUserStore } from '../page/user.store'
import { Placing } from './placing'
import styles from './standings.module.css'
import { Standing } from './types'
import { faChevronDown, faChevronUp, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { Results } from './results'
import { useFollowRequest } from '../../api/follow'
import { Maybe } from '../../global'
import { useCallback } from 'react'
import toast from 'react-hot-toast'


export function Players({ player }: { player: Standing }) {
  const user = useUserStore() as Registree  
  const [isOpen, toggle] = useToggle(false)

  return <>
    <tr onClick={toggle} className={user.username === player.username ? `${styles.currentPlayer}` : ''}>
      <td>{hasValue(player.position) ? <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} /> : null}</td>
      <td className={styles.standingsPosition} ><Placing position={player.position} /></td>
      <td className={styles.standingsPlayer}>{player.username}</td>
      <td>{hasValue(player.position) ? player.points : "-"}</td>
      <td>{hasValue(player.position) ? player.bonus : "-"}</td>
      <td>{hasValue(player.position) ? player.total : "-"}</td>
      <td><Follow username={player.username} /></td>
    </tr>
    {isOpen && hasValue(player.position) ? <Results player={player} onClick={toggle} /> : null}
  </>
}

function hasValue(number : Maybe<number>) {
  return number !== undefined && number !== null
}

function Follow({ username }: { username: string }) {
  const followRequest = useFollowRequest()
  const follow = useCallback(async (username: string) => {
    await followRequest(username)
    toast(`You have followed ${username}`)
  },[followRequest])

  return <FontAwesomeIcon icon={faUserPlus} title="Follow" onClick={e => { follow(username); e.preventDefault(); e.stopPropagation(); }} className={styles.follow} />
}
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { Container } from '../../../components/layout/container'
import { Standings } from '../../../components/standings'
import { useStandingsViewModel } from './standings.vm'
import { Banner } from '../social/social'


export function CurrentStandings() {
  const { standings } = useStandingsViewModel()

return <Container>
    <Banner icon={faUserPlus} text='Follow' />
    <Standings standings={standings} />
  </Container>
}



import { DateTime} from 'luxon'
import { useCallback } from 'react'
import { useIsLargeScreen } from './use-media-query'

export function useDateDisplay() {
    const dateOptions = useIsLargeScreen() ? usingDates : usingShortDates
    return useCallback((start:DateTime, end:DateTime) => toDateDisplay(start, end, dateOptions), [dateOptions])
}

const usingDates: Intl.DateTimeFormatOptions = { weekday: 'short' }
const usingShortDates: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', year: '2-digit' }

export function toDateDisplay(start: DateTime, end: DateTime, options: Intl.DateTimeFormatOptions) {
  return spansSingleDay(start, end) ? toDate(start, options) : `${toDate(start, options)} - ${toDate(end, options)}`
}

function spansSingleDay(start:DateTime, end: DateTime) {
  return start.hasSame(end, 'day')
}

function toDate(dateTime: DateTime, options: Intl.DateTimeFormatOptions) { return dateTime.toLocaleString({ ...DateTime.DATE_HUGE, ...options }) }
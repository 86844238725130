import styles from './promotions.module.css'
import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faChevronDown, faChevronUp, faSquare, faCopy } from '@fortawesome/free-solid-svg-icons'
import { useToggle } from '../../../hooks/use-toggle'
import { Panel } from '../../../components/layout/panel'
import { LinkButton } from '../../../components/buttons/buttons'
import { Dialogue } from '../../../components/dialogue/dialogue'
import React from 'react'
import { usePromotionViewModel } from './promotions.vm'
import { Registree } from '../../../components/page/user.store'
import { Promotion } from '../../account/account.types'
import useClipboard from 'react-use-clipboard'
import { Tooltip } from 'react-tooltip'
import { Urls } from '../../../paths'

export function Promotions() {
  const [isOpen, toggle] = useToggle(false)
  const { user, showPromos } = usePromotionViewModel()

  return <>{showPromos() ? <Panel>
    <div onClick={toggle} className={styles.toggle}>
      <FontAwesomeIcon icon={faInfoCircle} />
      <span>My Promos</span>
      <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
    </div>
    {isOpen
      ? <div className={styles.promotions} aria-label='promotions'>
        <p>
          {(user as Registree).promotions.map(x => <Item key={`${x.description}`}>{`${x.description}`}
            <Promo promotion={x} />
          </Item>)}
        </p>
        <Tooltip id="copy_clicked" content="Copied to clipboard" openOnClick={true} />
      </div>

      : null}
  </Panel> : null}</>
}

function Item({ children }: { children: ReactNode }) {
  return <li className={styles.item} aria-label='promotion'>
    <FontAwesomeIcon fixedWidth icon={faSquare} />
    <span className={styles.itemText}>{children}</span>
  </li>
}

function Promo({ promotion }: { promotion: Promotion }) {
  const { attemptPurchase, prompt } = usePromotionViewModel()

  if (promotion.howToClaim === 'PurchaseTokens')
    return <React.Fragment key={promotion.description}>
      &nbsp;&nbsp;-<LinkButton onClick={attemptPurchase}>Buy Now</LinkButton>
      <Dialogue title={prompt.title} show={prompt.show} actions={[{label:'OK', action:prompt.confirm}]} onClose={prompt.cancel}>{prompt.message}</Dialogue>
    </React.Fragment>

  if (promotion.howToClaim === 'None')
    return <Referral />


  return null
}

function Referral() {
  const { user } = usePromotionViewModel()
  const referralLink = Urls.home((user as Registree).username)
  const [, setCopied] = useClipboard(referralLink)

  return <><br /><span className={styles.referral} >{referralLink}&nbsp;&nbsp;<span role='button' aria-label='copy'><FontAwesomeIcon data-tooltip-id="copy_clicked" icon={faCopy} onClick={setCopied} /></span></span></>
}

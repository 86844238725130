import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetTransactionsRequest } from '../../../api/get-transactions'
import { useTransactionsStore } from '../account.store'

export function useTransactionHistoryViewModel() {
  const [params] = useSearchParams()
  const getTransactions = useGetTransactionsRequest()
  const { transactions, setTransactions } = useTransactionsStore()

  useEffect(() => {
    (async function () {
      setTransactions(await getTransactions())
    })()
  }, [params, getTransactions, setTransactions])

  return { transactions, setTransactions }
}
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '../layout/container'
import styles from './title.module.css'

export type TitleProps = { icon: IconDefinition, title: string, matchday: string }

export function Title({ icon, title, matchday }: TitleProps) {

  return <Container>
    <div className={styles.title}>
      <h2 className={styles.left}>
        <FontAwesomeIcon icon={icon} />{title}
      </h2>
    </div>
  </Container>
}

import { Container } from '../../../components/layout/container';

import { Standings } from '../../../components/standings';
import { Banner } from '../social/social';
import { useMiniLeague } from './mini-league.vm';

export function MiniLeague() {
  const miniLeague = useMiniLeague()
  return <Container>    
    <Banner />
    <Standings standings={miniLeague} />
  </Container>
}

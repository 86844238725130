import styles from './standings.module.css'
import first from '../../images/1place.png'
import second from '../../images/2place.png'
import third from '../../images/3place.png'
import { Maybe } from '../../global'

export function Placing({ position }: { position: Maybe<number> }) {
  if (false && position === 1) return <img className={styles.trophyFirst} src={first} alt='first' />
  if (false && position === 2) return <img className={styles.trophy} src={second} alt='first' />
  if (false && position === 3) return <img className={styles.trophy} src={third} alt='first' />
  return <span className={styles.position}>{position}</span>
}
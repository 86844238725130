export type GameStub = {
    game: { name: string },
    round: { id: string, name: string, start: string, end: string },
    offering: { stake: { cash: number }, pot: number }
}

export class GamesListBuilder {
    list(): GameStub[] {
        return [
            {
                game: { name: 'Prem Predictor' },
                round: {
                    id: '39-2024-4', name: 'Matchday 4',
                    "start": "2024-09-14T11:30:00Z",
                    "end": "2024-09-15T15:30:00Z",
                },
                offering: { stake: { cash: 5 }, pot: 250 }
            },
            {
                game: { name: 'Prem Predictor' },
                round: {
                    id: '39-2024-5', name: 'Matchday 5',
                    "start": "2024-09-21T11:30:00Z",
                    "end": "2024-09-22T15:30:00Z",
                },
                offering: { stake: { cash: 5 }, pot: 250 }
            },
            {
                game: { name: 'Prem Predictor' },
                round: {
                    id: '39-2024-6', name: 'Matchday 6',
                    "start": "2024-09-28T11:30:00Z",
                    "end": "2024-09-30T19:00:00Z",
                },
                offering: { stake: { cash: 5 }, pot: 250 }
            },
            {
                game: { name: 'Prem Predictor' },
                round: {
                    id: '39-2024-7', name: 'Matchday 7',
                    "start": "2024-10-05T11:30:00Z",
                    "end": "2024-10-06T15:30:00Z",
                },
                offering: { stake: { cash: 5 }, pot: 250 }
            },
        ]
    }
}


import { Panel } from '../../../components/layout/panel'
import { formatMoney } from '../../../global'
import styles from './offering.module.css'
import merge from 'classnames'
import { useOfferingViewModel } from './offering.vm'

type OfferingProps = {
  action: React.ReactNode,
  offering: ReturnType<typeof useOfferingViewModel>
}

export function Offering({ action, offering }: OfferingProps) {
  return <Panel className={styles.details}>
    { offering.showPlayersUntilIncrement ? <div className={merge(styles.cell, styles.nextIncrease)} key='first'>
      Prize pot will increase in {offering.nextIncrease} players
    </div> : null }
    <div className={merge(styles.cell, styles.first)}>
      <div>1st</div>
      <div aria-label='First Prize'>{formatMoney(offering.first)}</div>
    </div>
    <div className={merge(styles.cell, styles.second)}>
      <div>2nd</div>
      <div aria-label='Second Prize'>{formatMoney(offering.second)}</div>
    </div>
    <div className={merge(styles.cell, styles.third)}>
      <div>3rd</div>
      <div aria-label='Third Prize'>{formatMoney(offering.third)}</div>
    </div>
    <div className={merge(styles.cell, styles.minorPlace)}>
      <div>4th</div>
      <div aria-label='Fourth Prize'>{formatMoney(offering.fourth)}</div>
    </div>
    <div className={merge(styles.cell, styles.minorPlace, styles.last)}>
      <div>5th</div>
      <div aria-label='Fifth Prize'>{formatMoney(offering.fifth)}</div>
    </div>
    <div className={styles.freeEntries}>
      <div>Free Entries</div>
      <div aria-label='Free Entries'>{offering.entries}</div>
    </div>
    {
      <div className={styles.players}>
        <div>Players</div>
        <div aria-label='Player Count'>{offering.players}</div>
      </div>
    }
    {!!action ? <div className={styles.actions} >{action}</div> : null}
  </Panel>
}


import styles from './games-list.module.css'
import { Game as GameType } from "./games-list.types"
import { useDateDisplay } from "../../../hooks/use-date-display"
import { useGamesListViewModel } from "./games-list.vm"
import { Container } from '../../../components/layout/container'
import { Button } from '../../../components/buttons/buttons'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../../paths'
import { Highlight } from '../carousel/panel'
import { ShareMe } from '../../game/social/social'
import { useUserStore } from '../../../components/page/user.store'
import { formatMoney } from '../../../global'
import { Fragment } from 'react'

export function GamesList() {
    const { games } = useGamesListViewModel()
    const user = useUserStore()

    return <Container>
        <ul aria-label="games" className={styles.games}>
            {games.map((g, i) => {
                const isEntrant = user.signedIn && user.playerProfile.isEntrant
                return <Fragment key={g.round.id}>
                    {i === 0 && (isEntrant ? <ReferAFriend /> : <SecondGameFree game={games[0]} />)}
                    {i === 1 && !isEntrant ? <ReferAFriend /> : null}
                    {i === 3 && window.togglesOn ? <FiveForFour /> : null}
                    <Game key={g.round.id} game={g} />
                </Fragment>
            })}
        </ul>
    </Container>
}

function Game({ game }: { game: GameType }) {
    const dates = useDateDisplay()
    const navigate = useNavigate()

    return <li data-round-id={game.round.id} aria-label='game' className={styles.game}>
        <div className={styles.gameHeader}>
            <h2 aria-label='Game Name' className={styles.gameName}>{game.game.name}</h2>
            <Info />
        </div>

        <div className={styles.round}>
            <h3 aria-label='Round Name' className={styles.roundName}>{game.round.name}</h3>:&nbsp;
            <div aria-label='When' className={styles.roundDates}>{dates(game.round.start, game.round.end)}</div>
        </div>

        <div className={styles.details}>
            <Trophy />
            <div aria-label='Pot' className={styles.pot}>£{game.offering.pot}</div>
            <div className={styles.potLabel}>Prize Pot</div>

            <div className={styles.entryLabel}>Entry</div>
            <div aria-label='Cash Stake' className={styles.cashStake}>{formatMoney(game.offering.stake.cash)}</div>
            <Button onClick={e => {
                e.preventDefault()
                e.stopPropagation()

                navigate(Path.game(game.round.id))
            }}>View</Button>
        </div>
    </li>
}

function Info() {
    const navigate = useNavigate()

    return <svg onClick={() => { navigate(Path.rules('epl')) }} className={cx(styles.mediumIcon, styles.gameInfo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
    </svg>
}

function Trophy() {
    return <svg className={cx(styles.largeIcon, styles.trophy)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0" />
    </svg>
}

function Promo({ children, onClick }: React.PropsWithChildren<{ onClick?: () => void }>) {
    return <li className={styles.promo} onClick={onClick}>{children}</li>
}

function SecondGameFree({ game }: { game: GameType }) {
    const navigate = useNavigate()
    return <Promo onClick={() => { navigate(Path.game(game.round.id)) }}>
        Play your first game and <Highlight>the second is free</Highlight>
    </Promo>
}

function ReferAFriend() {
    return <Promo>
        Share to receive free entries<ShareMe />
    </Promo>
}

function FiveForFour() {
    return <Promo>
        <Highlight>Buy 5</Highlight> game entries for the price of four
    </Promo>
}
import { useCallback, useEffect, useState } from 'react'
import { produce } from 'immer'
import create from 'zustand'
import { Maybe } from '../../global'
import { useGetGameRequest } from '../../api/get-game'
import { Game, Team } from './game.types'

export function useIsPlayingStore() { return useGameStore(x => x.game?.player.isPlaying ?? false) }
export function useOfferingStore() { return useGameStore(x => x.game!.offering) }
export function useMatchdayStore() { return useGameStore(x => x.game!.matchday) }
export function useEnteringStore() { return useGameStore(x => x.hasEntered) }
export function usePredictingStore() { return useGameStore(x => ({ makePrediction: x.makePrediction, clearSaveMessage: x.clearSaveMessage })) }
export function useStandingsStore() { return useGameStore(x => x.game!.standings) }
export function usePlayerStore() { return useGameStore(x => x.game!.player) }

export function useLoadGameFor(matchdayId?: string) {
  const [loading, setLoading] = useState(true)

  const getGame = useGetGameRequest()
  const setGame = useGameStore(useCallback(state => state.setGame, []))

  useEffect(() => {
    (async () => {
      setLoading(true)
      const game = await getGame(matchdayId)
      setGame(game)
      setLoading(false)
    })()
  }, [matchdayId, getGame, setGame])

  return loading
}

type GameStore = {
  game: Maybe<Game>,
  setGame: (game: Game) => void,
  makePrediction: (reference: number, team: Team, prediction: number, showSavedMessage: boolean) => void,
  clearSaveMessage: (reference: number) => void,
  hasEntered: () => void
}

const useGameStore = create<GameStore>((set, get) => ({
  game: null,
  setGame(game) { set({ game }) },
  makePrediction(reference, team, prediction, showSavedMessage) {
    if (!get().game) return

    const game = produce(get().game, draft => {
      const fixture = draft!.matchday.fixtures.find(x => x.reference === reference)
      if (fixture && showSavedMessage) fixture.justSaved = true
      if (team.side === 'home' && fixture) fixture.homeTeam.prediction = prediction
      if (team.side === 'away' && fixture) fixture.awayTeam.prediction = prediction
    })

    set({ game })
  },
  clearSaveMessage(reference) {
    if (!get().game) return

    const game = produce(get().game, draft => {
      const fixture = draft!.matchday.fixtures.find(x => x.reference === reference)
      if (fixture) fixture.justSaved = false
    })

    set({ game: game })
  },
  hasEntered() {
    const matchday = get().game
    if (matchday)
      set({ game: { ...matchday, player: { ...get().game!.player, isPlaying: true } } })
  }
}))

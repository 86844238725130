import { useGetDepositPageUrlRequest } from '../../../api/get-deposit-page-url'
import { useForm } from 'react-hook-form'

export type DepositAmountForm = { amount: number }

export function useDepositsViewModel() {
  const { handleSubmit, register, formState: { errors } } = useForm<DepositAmountForm>({ mode: "onTouched" })
  const getDepositPage = useGetDepositPageUrlRequest()

  async function redirect(amount: number) {
    window.location.href = await getDepositPage(amount)
  }

  return {
    depositAmount: handleSubmit(form => redirect(form.amount)),
    depositFixed: redirect,
    validation: {
      register() { return register("amount", validation) },
      errors
    }
  }
}

const validation = {
  required: "Amount is required",
  min: { value: 5, message: "Miniumum deposit: £5" },
  max: { value: 200, message: "Maximum deposit: £200" },
  pattern: { value: /^\d*(\.\d{0,2})?$/, message: "Must be a valid amount of money" }
}
import styles from './account.module.css'
import { Page } from '../../components/page/page'
import { Panel } from '../../components/layout/panel'
import { Deposits } from './deposits/deposits'
import { TransactionHistory } from './transaction-history/transaction-history'
import { Withdrawal } from './withdrawals/withdrawal'
import { Balance } from './balance/balance'

export function Account() {
  return <Page><AccountContents /></Page>
}

function AccountContents() {
  return <Panel narrow>
    <div className={styles.accountHeading}>
      <h1 className={styles.heading}>Account</h1>
    </div>
    <Balance />
    <Deposits />
    <Withdrawal />
    <TransactionHistory />
  </Panel >
}

import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { Container } from '../../../components/layout/container'
import styles from './info-pane.module.css'
import { useInfoPane } from './info-pane.vm'

export function InfoPane() {
  const { show, items } = useInfoPane()

  return show ? <Container>
    <ul className={styles.infoPane}>
      {items.map(x => <InfoItem key={x}>{x}</InfoItem>)}
    </ul>
  </Container> : null
}

function InfoItem({ children }: { children: ReactNode }) {
  return <li className={styles.infoItem} role='status'>
    <FontAwesomeIcon fixedWidth icon={faSquare} />
    <span className={styles.itemText}>{children}</span>
  </li>
}
import { Container } from '../../../components/layout/container'
import { Page } from '../../../components/page/page'
import styles from './rules.module.css'
import { CallToAction } from '../../../components/buttons/buttons'
import { Panel } from '../../../components/layout/panel'
import { TourConduction } from './tour'
import { HowItWorks } from './how-it-works/how-it-works'
import { Path } from '../../../paths'
import { useParams } from 'react-router-dom'
import { GameType } from '../type'
import { Games } from '../games'
import { Tours } from './tour/tours'
import { useUserStore } from '../../../components/page/user.store'

export function Rules() {
  return <Page><RulesContentsFromParams /></Page>
}

function RulesContentsFromParams() {
  const { type } = useParams<{ type: GameType }>()
  return <RulesContents type={type} />
}

export function RulesContents({ type }: { type?: GameType }) {
  const { signedIn } = useUserStore()

  return !!type ? <>
    <Container>
      <h1 className={styles.header}>{Games[type].name}</h1>
    </Container>
    <Panel>
      <TourConduction tour={Tours[type]} />
    </Panel>
    {!signedIn ? <div className={styles.playNow}><CallToAction to={Path.dashboard}>GAMES</CallToAction><CallToAction to={Path.signup}>SIGN UP</CallToAction></div> : null}
    <HowItWorks alwaysOpen bonusPointText={Games[type].bonusPointText} prizeGrowthText={Games[type].prizeGrowthText} />
    &nbsp;
  </> : <Container>No tour found for this game</Container>
}
import { useState } from 'react'
import { DateTime } from 'luxon'
import { useSignupRequest, SignupPayload } from '../../api/signup'

export type SignupForm = Omit<SignupPayload, 'dateOfBirth'> & { dobDay: string, dobMonth: string, dobYear: string }

export function useSignupViewModel() {
  const signupRequest = useSignupRequest()
  const [informOfVerification, setInformOfVerification] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  async function signup(form: SignupForm) {
    const { dobDay, dobMonth, dobYear, ...everythingElse } = form
    let errored = false
    await signupRequest({ ...everythingElse, dateOfBirth: getDateOfBirth(form) }, problem => { setErrors(problem.errors); errored = true })

    if (!errored)
      setInformOfVerification(true)
  }

  return { signup, informOfVerification, errors }
}

function getDateOfBirth(form: SignupForm) {
  return DateTime.fromObject({ day: parseInt(form.dobDay), month: parseInt(form.dobMonth), year: parseInt(form.dobYear) })
}
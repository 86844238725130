import { useMemo } from 'react'
import { useUserStore } from '../../../components/page/user.store'
import { useGameViewModel } from '../game.vm'

export function useInfoPane() {
  const user = useUserStore()
  const { isPlaying, entryClosed, entryTimeLeft, isOver } = useGameViewModel()

  const show = useMemo(() => user.signedIn && !isOver && (isPlaying || entryClosed || entryTimeLeft), [entryClosed, entryTimeLeft, isOver, isPlaying, user.signedIn])
  const items = useMemo(() => {
    let itemBuilder = []

    if(isPlaying) itemBuilder.push('You are entered to this game')
    if(!isPlaying && entryClosed) itemBuilder.push('Entry has now closed')
    if(entryTimeLeft) itemBuilder.push(`Game entry closes in ${entryTimeLeft}`)

    return itemBuilder
  },[entryClosed, entryTimeLeft, isPlaying])
  
  return { show, items }
}
import styles from './signup.module.css'
import { DeepRequired, FieldErrorsImpl, UseFormReturn, UseFormSetValue } from 'react-hook-form'
import { Input } from './input'
import { SignupForm } from './signup.vm'
import { find } from 'getaddress-find'
import { useEffect, useState } from 'react'

export function Address({ form }: { form: UseFormReturn<SignupForm, object> }) {
  const { register, formState: { errors }, setValue } = form
  const [showAddress, setShowAddress] = useState(false)
  const onShowAddressClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { e.stopPropagation(); e.preventDefault(); setShowAddress(true) }
  useGetAddress(setShowAddress, setValue)

  return <>
    <label className={styles.searchLabel}>Search for Postcode/Address</label>
    <div id="get_address" className={styles.getAddress}></div>
    <VisibleWhen predicate={!showAddress} className={styles.enterManuallyContainer}>
      <button onClick={onShowAddressClick} className={styles.enterManually}>Enter Manually</button>
    </VisibleWhen>

    <VisibleWhen predicate={showAddress || addressHasErrors(errors)} className={styles.addressControls}>
      <Input label="House" errors={errors} {...register("house", { required: "Please enter house no./name" })} />
      <Input label="Address Line 1" {...register("addressLine1", { required: "Please enter address 1" })} errors={errors} />
      <Input label="Address Line 2" errors={errors} {...register("addressLine2")} />
      <Input label="Town/City" errors={errors} {...register("townOrCity")} />
      <Input label="County" errors={errors} {...register("county")} />
      <Input label="Postcode" maxLength={15} errors={errors} {...register("postcode", { required: "Please enter your postcode" })} />
    </VisibleWhen>
  </>
}

function VisibleWhen({ predicate, children, className }: { predicate: boolean, children: JSX.Element | JSX.Element[], className?: string }) {
  return <div style={!predicate ? { visibility: 'hidden', maxHeight: 0 } : {}} className={className}>
    {children}
  </div>
}

function addressHasErrors(errors: FieldErrorsImpl<DeepRequired<SignupForm>>): boolean {
  return !!errors.house || !!errors.addressLine1 || !!errors.postcode
}

function useGetAddress(setShowAddress: (show: boolean) => void, setValue: UseFormSetValue<SignupForm>) {
  useEffect(() => {
    find('get_address', window.configuration.getAddress.apiKey)

    const onSelected = (e: any) => {
      setShowAddress(true)
      const event = e as AddressEvent

      setValue("house", premise(event))
      setValue("addressLine1", event.address.thoroughfare)
      setValue("addressLine2", "")
      setValue("townOrCity", event.address.town_or_city)
      setValue("county", event.address.county)
      setValue("postcode", event.address.postcode)
    }

    document.addEventListener("getaddress-find-address-selected", onSelected)

    return () => {
      document.removeEventListener("getaddress-find-address-selected", onSelected)
    }
  }, [setShowAddress, setValue])
}

function premise(e: AddressEvent) {
  let premise = e.address.sub_building_name

  if (e.address.building_name !== '')
    premise += `${premise === '' ? '' : ', '}${e.address.building_name}`

  if (e.address.building_number !== '')
    premise += `${premise === '' ? '' : ', '}${e.address.building_number}`

  return premise
}


type AddressEvent = {
  address: {
    building_name: string,
    building_number: string,
    country: string,
    county: string,
    district: string,
    formatted_address: string[],
    latitude: number,
    line_1: string,
    line_2: string,
    line_3: string,
    line_4: string,
    locality: string,
    longitude: number,
    postcode: string,
    residential: boolean,
    sub_building_name: string,
    sub_building_number: string,
    thoroughfare: string,
    town_or_city: string
  }
}

import { useCallback } from 'react'
import { usePost } from '../utilities/http/use-authenticated-api'
import { Prediction } from './payloads'
import routes from './routes'

export function useEnterGameRequest() {
  const post = usePost()
  return useCallback(async (game: string, stake: 5 | 0, predictions: Prediction[]): Promise<void> => {
    await post(routes.enterGame(game), { stake, predictions })
  }, [post])
}
import { DateTime } from 'luxon';
import { useMatchdayStore, useOfferingStore } from '../game.store';


export function useOfferingViewModel() {
  const offering = useOfferingStore() 
  const matchday = useMatchdayStore()

  const hasStarted = matchday.start < DateTime.now()

  return { ...offering, showPlayersUntilIncrement: !hasStarted }
}
import { useEffect, useState } from "react"
import { useGetGames } from "../../../api/get-games"
import { Game } from "./games-list.types"

export function useGamesListViewModel() {
    const [games, setGames] = useState<Game[]>([])
    const getGames = useGetGames()    
    
    useEffect(() => {
        (async function () {
            const games : Game[] = await getGames()
            setGames(games)
        })()
    },[getGames])

    return { games }
}

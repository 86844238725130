import { Page } from '../../../components/page/page'
import { Path } from '../../../paths'

export function Withdrawn() {
  return <Page><WithdrawnContents /></Page>
}

function WithdrawnContents() {
  window.location.assign(`/#${Path.account}`)
  return <></>
}
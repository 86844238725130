import { useCallback } from 'react'
import { Money } from '../global'
import { usePost } from '../utilities/http/use-authenticated-api'
import routes from './routes'

type Deposit = { id: string, user: string, amount: Money, timestamp: string, checksum: string }

export function useTakeDepositRequest() {
  const post = usePost()
  return useCallback(async (deposit: Deposit): Promise<void> => {
    await post(routes.deposit, deposit)
  }, [post])
}
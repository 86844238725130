import { Registree, useUserStore } from '../../../components/page/user.store';
import { formatMoney } from '../../../global';
import styles from '../account.module.css'

export function Balance() {
  const { balance: { cash, tokens } } = useUserStore() as Registree

  return <div className={styles.balances}>
    <div>
      <label id="monetary-balance-label">Your Cash Balance:</label>
      <span aria-labelledby="monetary-balance-label">{formatMoney(cash)}</span>
    </div>

    <div>
      <label id="token-balance-label">Your Entry Tokens:</label>
      <span aria-labelledby="token-balance-label">{tokens} game entries</span>
    </div>
  </div>
}
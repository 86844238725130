import styles from '../../components/forms/forms.module.css'
import { forwardRef, useMemo } from 'react'
import { v4 as uniqueId } from 'uuid'
import { FieldErrors } from 'react-hook-form'
import { ErrorMessage } from '../../components/forms/error-message'
import { SignupForm } from './signup.vm'

type InputProps = {
  label: string,
  name: keyof SignupForm,
  errors: FieldErrors<SignupForm>
} & Omit<JSX.IntrinsicElements["input"], "name">

export const Input = forwardRef<HTMLInputElement, InputProps>(({ label, errors, id, maxLength, ...props }: InputProps, ref) => {
  const inputId = useMemo(() => id ?? uniqueId(), [id])

  return <div className={styles.controlContainer}>
    <label htmlFor={inputId}>{label}</label>
    <ErrorMessage error={errors[props.name]?.message} />
    <input id={inputId} maxLength={maxLength ?? 70} aria-invalid={!!errors[props.name]} {...props} ref={ref} />
  </div>
})